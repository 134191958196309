import { useFormikContext } from 'formik';
import { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useAppSelector from 'core/hooks/useAppSelector';
import TimeZone from 'core/models/TimeZone';

import { useIncludeOptions } from '../ResourceFormDropdown/IncludeResourcesProvider';

import TimeZoneModal from '.';

export interface Props {
  modified?: Date | null;
  created?: Date | null;
  open: boolean;
  onClose: () => void;
  setChosenTimeZone: (name: string) => void;
}

const DetailTimeZoneModalWrapper: FC<Props> = ({
  modified,
  created,
  open,
  onClose,
  setChosenTimeZone,
}) => {
  const { t } = useTranslation();
  const formik = useFormikContext<Record<string, any>>();
  // Having access to timeZonesId in formik context means this is used inside form
  const usesFormTimeZone = formik?.values && 'timeZonesId' in formik.values;

  const formTimeZone = useIncludeOptions('timeZonesId')?.[0] as TimeZone;

  const profileTimeZone = useAppSelector(({ core }) => core.user?.timeZone);

  const onTimeZoneChange = useCallback(
    (id: number, name: string) => {
      formik.setFieldValue('timeZonesId', id);
      setChosenTimeZone(name);
    },
    [formik, setChosenTimeZone]
  );

  // Trigger initial time zone change with the include/profile time zone
  useEffect(() => {
    if (formTimeZone) {
      setChosenTimeZone(formTimeZone.name);
    } else if (profileTimeZone) {
      setChosenTimeZone(profileTimeZone.name);
    } else {
      setChosenTimeZone(t('Time Zone'));
    }
  }, [formTimeZone, profileTimeZone, setChosenTimeZone, t]);

  return (
    <TimeZoneModal
      open={open}
      onClose={onClose}
      created={created}
      modified={modified}
      usesFormTimeZone={usesFormTimeZone}
      onTimeZoneChange={onTimeZoneChange}
    />
  );
};

export default DetailTimeZoneModalWrapper;
