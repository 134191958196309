import { DialogOverlay } from '@reach/dialog';
import { VisuallyHidden } from '@reach/visually-hidden';
import { FC, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Transition, TransitionStatus } from 'react-transition-group';
import { EXITED, UNMOUNTED } from 'react-transition-group/Transition';

import '@reach/dialog/styles.css';

import Button from 'core/components/Button';

import Props from './props';
import { Container, Content, Footer, PullLeft, Title } from './styled';

const Modal: FC<Props> = ({
  open,
  title,
  ariaLabel,
  className,
  autoResponsiveWidth: setWidth = true,
  padding,
  cancelButton: cancelBtn,
  confirmButton: confirmBtn,
  clearButton: clearBtn,
  onClose,
  onCancel,
  onConfirm,
  onClear,
  mountOnEnter = true,
  unmountOnExit = true,
  children,
}) => {
  const { t } = useTranslation();
  const overlayRef = useRef<HTMLDivElement | null>(null);

  const handleCancel = useCallback(() => {
    if (onCancel) {
      onCancel();
    }
    onClose();
  }, [onCancel, onClose]);
  const handleConfirm = useCallback(() => {
    if (onConfirm) {
      onConfirm();
    }
    onClose();
  }, [onConfirm, onClose]);

  const cancelButton =
    cancelBtn !== undefined ? (
      cancelBtn
    ) : (
      <Button type="button" text={t('Cancel')} onClick={handleCancel} />
    );
  const confirmButton =
    confirmBtn !== undefined ? (
      confirmBtn
    ) : (
      <Button type="button" text={t('Confirm')} onClick={handleConfirm} />
    );
  const clearButton = (onClear || clearBtn) && (
    <PullLeft>
      {clearBtn ? clearBtn : <Button type="button" text={t('Clear')} onClick={onClear} />}
    </PullLeft>
  );
  const hasFooter = !(!confirmButton && !cancelButton && !clearButton);
  const hasTitle = !!title;

  return (
    <>
      <Transition
        in={open}
        timeout={200}
        mountOnEnter={mountOnEnter}
        unmountOnExit={unmountOnExit}
        nodeRef={overlayRef}
      >
        {(state: TransitionStatus) => (
          <DialogOverlay
            ref={overlayRef}
            // Scroll won't work without this when the modal stays mounted but hidden
            dangerouslyBypassScrollLock={state === 'exited'}
            // Initial focus won't work without this when the modal stays mounted but hidden - https://cannypack.atlassian.net/browse/FEC-402
            dangerouslyBypassFocusLock={state === 'exited'}
            onDismiss={() => {
              onClose();
            }}
            aria-hidden={[EXITED, UNMOUNTED].includes(state)}
            // We have to use classes as props are not forwarded correctly and bcs of CSS transition
            className={[
              `animate-${state}`,
              setWidth && 'set-width',
              !hasTitle && 'no-title',
              className,
            ]
              .filter((c) => c)
              .join(' ')}
          >
            <Container aria-label={ariaLabel}>
              <VisuallyHidden onClick={onClose}>{t('Close')}</VisuallyHidden>

              {hasTitle && <Title>{title}</Title>}

              <Content padding={padding}>{children}</Content>

              {hasFooter && (
                <Footer>
                  {clearButton}
                  {cancelButton}
                  {confirmButton}
                </Footer>
              )}
            </Container>
          </DialogOverlay>
        )}
      </Transition>
    </>
  );
};

export default Modal;
