import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import { getWorkspace, login, switchLogin } from 'core/actions';
import Form from 'core/components/Form';
import Field from 'core/components/Form/Field';
import FormTextfield from 'core/components/FormTextfield';
import { Row } from 'core/components/Grid';
import { FullRowCell } from 'core/components/GridCell';
import SecondaryPage from 'core/components/SecondaryPage';
import { parse } from 'core/functions/qs';
import useAppSelector from 'core/hooks/useAppSelector';
import useNumbersOnlyFilter from 'core/hooks/useNumbersOnlyFilter';

import Fieldset from './Fieldset';
import useLoginSchema, { LoginFormData } from './useLoginSchema';

interface Props {
  loginRenewal?: boolean;
}

const LoginForm: FC<Props> = ({ loginRenewal = false }) => {
  const dispatch = useDispatch();

  const switchBackLogin = useCallback(() => dispatch(switchLogin(false)), [dispatch]);
  const { loginInProgress, twoFactorLoginMode, user } = useAppSelector(({ core }) => ({
    loginInProgress: core.loginInProgress,
    twoFactorLoginMode: core.twoFactorLoginMode,
    user: core.user,
  }));
  const { t } = useTranslation();

  const { key } = parse(window.location.search);
  const { email, workspace, backTo } = key
    ? parse(window.atob(key as string))
    : { email: '', workspace: '', backTo: window.location.origin };
  const loginSchema = useLoginSchema(twoFactorLoginMode);
  const initialValues = loginSchema.cast(
    loginRenewal ? { email: user?.email } : { email: decodeURIComponent(email as string) }
  );

  const numbersOnlyFilter = useNumbersOnlyFilter();
  const handleSubmit = useCallback(
    (values: LoginFormData) => {
      const passcode = values.passcode ? numbersOnlyFilter(values.passcode) : undefined;
      dispatch(
        login(values.email as string, values.password as string, passcode as string | undefined)
      );
    },
    [dispatch, numbersOnlyFilter]
  );

  const handleSubmitFirstStep = (values: LoginFormData) => {
    dispatch(getWorkspace(values.email as string));
  };

  const LoginFirstStepSchema = Yup.object({
    email: Yup.string().nullable().email().required().default(null),
  });
  return (!email || !workspace) && !loginRenewal ? (
    <Form
      // @ts-ignore
      onSubmit={handleSubmitFirstStep}
      validationSchema={LoginFirstStepSchema}
      initialValues={{ email: decodeURIComponent(email as string) || '' }}
      validateOnBlur={false}
      autoComplete="login"
      id="detailFormFirstStep"
    >
      <SecondaryPage.Grid>
        <Row>
          <FullRowCell>
            <Field
              component={FormTextfield}
              label={t('E-mail')}
              name="email"
              type="email"
              id="email"
              fast={false}
              autoComplete="username"
              icon="email"
            />
          </FullRowCell>
        </Row>

        <SecondaryPage.Actions>
          <SecondaryPage.Button raised text={t('Continue')} type="submit" />
        </SecondaryPage.Actions>
      </SecondaryPage.Grid>
    </Form>
  ) : (
    <Form<LoginFormData>
      onSubmit={loginInProgress ? undefined : handleSubmit}
      validationSchema={loginSchema}
      initialValues={initialValues}
      validateOnBlur={false}
      autoComplete="login"
      id="detailForm"
      viewMode={loginRenewal ? { password: false, passcode: false } : undefined}
    >
      <Fieldset
        switchBackLogin={switchBackLogin}
        inProgress={loginInProgress}
        twoFactor={twoFactorLoginMode}
        backTo={backTo as string}
      />
    </Form>
  );
};

export default LoginForm;
