import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import theme, { StatusColor } from 'app/theme';
import Badge from 'core/components/Badge';
import MaterialIcon, { MaterialIconProps } from 'core/components/MaterialIcon';
import Media from 'core/components/Media';
import Tooltip from 'core/components/Tooltip';

import { BadgeWrapper, MetaGroup, StatusWrap, StyledContent } from './styled';
import Tabs, { TabOption } from './Tabs';
import Time from './Time';

interface Props {
  statusText?: string;
  statusColor?: StatusColor;
  statusIcon?: string; // TODO: Improve types: https://cannypack.atlassian.net/browse/FEC-391
  options?: TabOption[];
  modified?: Date;
  created?: Date;
  icons?: MaterialIconProps[];
  statusBadgeVariant?: string;
  statusBadgeText?: string;
  archived?: boolean;
  /**
   * Allows changing the timezone and updating the Formik context if provided.
   * Mandatory for forms that include a timezone value.
   */
  useFormikTimeZone?: boolean;
}

const DetailSubheader: FC<Props> = ({
  statusText,
  statusColor,
  statusIcon,
  statusBadgeVariant,
  statusBadgeText,
  options,
  modified,
  created,
  archived,
  icons,
  useFormikTimeZone,
}) => {
  const { t } = useTranslation();

  const statusIcons = useMemo(
    () =>
      (archived === true
        ? [
            {
              tooltip: t('Archived'),
              icon: 'archive',
              color: theme.color.status.gray,
            } as MaterialIconProps,
          ]
        : []
      ).concat(icons || []),
    [archived, icons, t]
  );

  const statusEl =
    statusText && statusColor ? (
      <StatusWrap data-cy="entity-status">
        <Tooltip content={statusText}>
          <MaterialIcon
            icon={statusIcon || theme.color.statusIcons[statusColor]}
            color={theme.color.status[statusColor]}
          />
        </Tooltip>
        {statusBadgeText && (
          <BadgeWrapper>
            <Badge variant={statusBadgeVariant}>{statusBadgeText}</Badge>
          </BadgeWrapper>
        )}
      </StatusWrap>
    ) : null;

  const statusIconsEl = (
    <>
      {statusIcons.length > 0
        ? statusIcons.map((el) => (
            <StatusWrap>
              <MaterialIcon size={1.2} color={theme.color.status.gray} {...el} key={el.icon} />
            </StatusWrap>
          ))
        : null}
    </>
  );
  const showTime = modified || created || useFormikTimeZone;

  const timeEl = (
    <>
      {showTime ? (
        <Time modified={modified} created={created} useFormikTimeZone={useFormikTimeZone} />
      ) : null}
    </>
  );

  return (
    <StyledContent>
      {options && options.length > 0 && <Tabs options={options} />}
      {statusEl || statusIconsEl || timeEl ? (
        <MetaGroup>
          <Media query="tabletDesktop">
            {statusEl}
            {statusIconsEl}
            {timeEl}
          </Media>
        </MetaGroup>
      ) : null}
    </StyledContent>
  );
};

export default DetailSubheader;
