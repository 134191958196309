import { toast } from 'react-toastify';
import { put, race, take } from 'redux-saga/effects';

import { apiCall, beginEditEntity, finishEditEntity, loadProfile } from 'core/actions';
import { t } from 'core/i18n';
import {
  EditControlSettingsAction,
  TYPE_EDIT_CONTROL_SETTINGS,
  TYPE_LOAD_CONTROL_SETTINGS,
} from 'settings/actions/index';
import { SAMPLE_CODE_TYPES } from 'settings/containers/ControlSettingsPage/Defaults/components/SampleCodesPaper';
import {
  ENABLED_INVOICE_AUTO_APPROVAL_DURATION_DAYS,
  VALUE_INVOICE_AUTO_APPROVAL_DURATION_DAYS,
} from 'settings/containers/ControlSettingsPage/Features/AutomaticApproval';
import { autoArchiveOptions } from 'settings/containers/ControlSettingsPage/Features/useAutoArchiveItems';
import {
  DEFAULT_TEST_PUBLISH_OFFSET,
  SampleCodeType,
} from 'settings/containers/ControlSettingsPage/useSchema';

export function* loadControlSettingsSaga() {
  yield put(
    apiCall(
      TYPE_LOAD_CONTROL_SETTINGS,
      'GET',
      `/client/settings`,
      {},
      {
        params: {
          offset: 0,
          limit: 50000,
        },
      }
    )
  );
}

export function* editControlSettingsSaga(action: EditControlSettingsAction) {
  yield put(beginEditEntity());

  const { fd, successCallback } = action.payload;

  const autoArchiveSettings = autoArchiveOptions.reduce(
    (acc, option) => {
      // Skip the setting if it's not in the form data at all
      if (fd[`enabled_${option.key}`] === undefined) return acc;

      acc.push(
        option.type === 'BOOLEAN'
          ? {
              key: option.key,
              value: fd[`enabled_${option.key}`] ? 1 : 0,
            }
          : {
              key: option.key,
              value: fd[`enabled_${option.key}`] ? (fd[`value_${option.key}`] as number) : null,
            }
      );

      return acc;
    },
    [] as Array<{ key: string; value: number | null }>
  );

  const controlSettings = [
    {
      key: 'enableCancelControl',
      value: fd['enabled_enableCancelControl'] ? '1' : '0',
    },
    {
      key: 'enableAddressCopying',
      value: fd['enabled_enableAddressCopying'] ? '1' : '0',
    },
    {
      key: 'arrivalDelayCheckEnabled',
      value: fd['enabled_arrivalDelayCheckEnabled'] ? '1' : '0',
    },
    {
      key: 'arrivalDelayMax',
      value:
        (fd['enabled_arrivalDelayCheckEnabled'] && Number(fd['value_arrivalDelayMax'])) || null,
    },
    {
      key: 'filterAthletePersonalData',
      value: fd['enabled_filterAthletePersonalData'] ? '1' : '0',
    },
    {
      key: 'requireLocking',
      value: fd['enabled_requireLocking'] ? '1' : '0',
    },
    {
      key: 'athleteDocumentNumber',
      value: fd['value_athleteDocumentNumber'] || null,
    },
    {
      key: 'hideAthleteDocumentNumber',
      value: fd['enabled_hideAthleteDocumentNumber'] ? '1' : '0',
    },
    {
      key: 'athleteNationalityMandatory',
      value: fd['enabled_athleteNationalityMandatory'] ? '1' : '0',
    },
    {
      key: 'enableLockTimer',
      value: fd['enabled_enableLockTimer'] ? '1' : '0',
    },
    {
      key: 'lockTimerTimeout',
      value: (fd['enabled_enableLockTimer'] && Number(fd['value_lockTimerTimeout'])) || null,
    },
    {
      key: 'defaultFederationsId',
      value:
        (fd['enabled_defaultFederationsId'] && Number(fd['value_defaultFederationsId'])) || null,
    },
    {
      key: 'defaultDebtorsId',
      value: (fd['enabled_defaultDebtorsId'] && Number(fd['value_defaultDebtorsId'])) || null,
    },
    {
      key: 'defaultCustomersId',
      value: (fd['enabled_defaultCustomersId'] && Number(fd['value_defaultCustomersId'])) || null,
    },
    {
      key: 'tdpClassPreference',
      value: Number(fd['value_tdpClassPreference']) || null,
    },
    {
      key: 'defaultTestAuthority',
      value:
        (fd['enabled_defaultTestAuthority'] && Number(fd['value_defaultTestAuthority'])) || null,
    },
    {
      key: 'defaultNotificationType',
      value:
        (fd['enabled_defaultNotificationType'] && Number(fd['value_defaultNotificationType'])) ||
        null,
    },
    {
      key: 'defaultUrineManufacturer',
      value: Number(fd['value_defaultUrineManufacturer']) || null,
    },
    {
      key: 'defaultBloodManufacturer',
      value: Number(fd['value_defaultBloodManufacturer']) || null,
    },
    {
      key: 'defaultUrineLaboratory',
      value:
        (fd['enabled_defaultUrineLaboratory'] && Number(fd['value_defaultUrineLaboratory'])) ||
        null,
    },
    {
      key: 'defaultBloodLaboratory',
      value:
        (fd['enabled_defaultBloodLaboratory'] && Number(fd['value_defaultBloodLaboratory'])) ||
        null,
    },
    {
      key: 'defaultResultAuthority',
      value:
        (fd['enabled_defaultResultAuthority'] && Number(fd['value_defaultResultAuthority'])) ||
        null,
    },
    {
      key: 'defaultSampleCollectionAuthority',
      value:
        (fd['enabled_defaultSampleCollectionAuthority'] &&
          Number(fd['value_defaultSampleCollectionAuthority'])) ||
        null,
    },
    {
      key: 'defaultForDcc',
      value: (fd['enabled_defaultForDcc'] && Number(fd['value_defaultForDcc'])) || null,
    },
    {
      key: 'defaultForEventName',
      value: (fd['enabled_defaultForEventName'] && Number(fd['value_defaultForEventName'])) || null,
    },
    {
      key: 'defaultForAdamsAthleteLevelsId',
      value:
        (fd['enabled_defaultForUseAdamsAthleteLevel'] &&
          Number(fd['value_defaultForAdamsAthleteLevelsId'])) ||
        null,
    },
    {
      key: 'covidDefaultAddress',
      value: (fd['enabled_covidDefaultAddress'] && Number(fd['value_covidDefaultAddress'])) || null,
    },
    {
      key: 'alternativeLanguage',
      value: fd['value_alternativeLanguage'] || null,
    },
    {
      key: 'availableDocumentLanguages',
      value: fd['value_availableDocumentLanguages'] || null,
    },
    {
      key: 'testPublishOffset',
      value: Number(fd['value_testPublishOffset']) || DEFAULT_TEST_PUBLISH_OFFSET,
    },
    {
      key: 'defaultAvailabilityCategoriesId',
      value:
        (fd['enabled_defaultAvailabilityCategoriesId'] &&
          Number(fd['value_defaultAvailabilityCategoriesId'])) ||
        null,
    },
    {
      key: 'defaultCallingCode',
      value: fd['value_defaultCallingCode'] || null,
    },
    {
      key: 'defaultCocShippingType',
      value:
        (fd['enabled_defaultCocShippingType'] && Number(fd['value_defaultCocShippingType'])) ||
        null,
    },
    {
      key: 'defaultCocLaboratory',
      value:
        (fd['enabled_defaultCocLaboratory'] && Number(fd['value_defaultCocLaboratory'])) || null,
    },
    {
      key: 'showCardAddressEndDate',
      value: fd['enabled_showCardAddressEndDate'] ? '1' : '0',
    },
    {
      key: 'enableTeamAndBackNumber',
      value: fd['enabled_enableTeamAndBackNumber'] ? '1' : '0',
    },
    {
      key: 'enablePoolInTheApp',
      value: fd['enabled_enablePoolInTheApp'] ? '1' : '0',
    },

    {
      key: 'enableTue',
      value: fd['enabled_enableTue'] ? '1' : '0',
    },
    {
      key: 'enableVenipuncture',
      value: fd['enabled_enableVenipuncture'] ? '1' : '0',
    },
    {
      key: 'enableBloodDcoSignature',
      value: fd['enabled_enableBloodDcoSignature'] ? '1' : '0',
    },
    {
      key: 'enableSocialSecurityNumber',
      value: fd['enabled_enableSocialSecurityNumber'] ? '1' : '0',
    },
    {
      key: 'enableAthletesConsent',
      value: fd['enabled_enableAthletesConsent'] ? '1' : '0',
    },
    {
      key: 'enableAthleteAddressInTheApp',
      value: fd['enabled_enableAthleteAddressInTheApp'] ? '1' : '0',
    },
    {
      key: 'cocDriverMandatory',
      value: fd['enabled_cocDriverMandatory'] ? '1' : '0',
    },
    {
      key: 'tdpFederationMandatory',
      value: fd['enabled_tdpFederationMandatory'] ? '1' : '0',
    },
    {
      key: 'enableDriedBloodSpots',
      value: fd['enabled_enableDriedBloodSpots'] ? '1' : '0',
    },
    {
      key: 'athleteZipMandatory',
      value: fd['enabled_enableAthleteAddressInTheApp']
        ? fd['enabled_athleteZipMandatory']
          ? '1'
          : '0'
        : null,
    },
    {
      key: 'socialSecurityNumberMandatory',
      value:
        (fd['enabled_enableSocialSecurityNumber'] &&
          fd['enabled_socialSecurityNumberMandatory'] &&
          '1') ||
        null,
    },
    {
      key: 'enableCoachDoctor',
      value: fd['enabled_enableCoachDoctor'] ? '1' : '0',
    },
    {
      key: 'enableAthleteAutoSetAsMaster',
      value: fd['enabled_enableAthleteAutoSetAsMaster'] ? '1' : '0',
    },
    {
      key: 'allowDcoDetailEdit',
      value: fd['enabled_allowDcoDetailEdit'] ? '1' : '0',
    },
    {
      key: 'showArchivedToDcos',
      value: fd['enabled_showArchivedToDcos'] ? '1' : '0',
    },
    {
      key: 'showAnalysesToAssignedDco',
      value: fd['enabled_showAnalysesToAssignedDco'] ? '1' : '0',
    },
    {
      key: 'covidSrf',
      value: Number(fd['value_covidSrf']),
    },
    {
      key: 'sessionLifetime',
      value: Number(fd['value_sessionLifetime']),
    },
    ...autoArchiveSettings,
    {
      key: 'defaultForIsPersonallyKnownAllowed',
      value: fd['enabled_defaultForIsPersonallyKnownAllowed'],
    },
    {
      key: 'defaultForRequireNotificationSignature',
      value: fd['enabled_defaultForRequireNotificationSignature'],
    },
    {
      key: 'defaultForAllowDelayDeadline',
      value: fd['enabled_defaultForAllowDelayDeadline'],
    },
    {
      key: 'defaultForPrefillSportDiscipline',
      value: fd['enabled_defaultForPrefillSportDiscipline'],
    },
    {
      key: 'defaultForSimplifiedSigning',
      value: fd['enabled_defaultForSimplifiedSigning'],
    },
    {
      key: 'defaultForAdditionalAssignments',
      value: fd['enabled_defaultForAdditionalAssignments'],
    },
    {
      key: 'defaultForUseAuthorityLogo',
      value: fd['enabled_defaultForUseAuthorityLogo'],
    },
    {
      key: 'defaultForUseAuthorityEmailTemplate',
      value: fd['enabled_defaultForUseAuthorityEmailTemplate'],
    },
    {
      key: 'defaultForUseAuthorityDcfTemplate',
      value: fd['enabled_defaultForUseAuthorityDcfTemplate'],
    },
    {
      key: 'defaultForFileTemplatesId',
      value:
        fd['value_defaultForFileTemplatesId'] && fd['value_defaultForFileTemplatesId'] !== 'default'
          ? Number(fd['value_defaultForFileTemplatesId'])
          : null,
    },
    {
      key: 'defaultForNotifyOfTeamUpdates',
      value: fd['enabled_defaultForNotifyOfTeamUpdates'],
    },
    {
      key: 'defaultForShowAthleteToDco',
      value: fd['enabled_defaultForShowAthleteToDco'],
    },
    {
      key: 'defaultForShowInternalCommentToDco',
      value: fd['enabled_defaultForShowInternalCommentToDco'],
    },
    {
      key: 'hideRestrictedAccessInUar',
      value: fd['enabled_hideRestrictedAccessInUar'],
    },
    {
      key: 'enableAutoChangeTestStatusToUnsuccessful',
      value: fd['enabled_enableAutoChangeTestStatusToUnsuccessful'],
    },
    {
      key: 'defaultForUse3genApp',
      value: fd['enabled_defaultForUse3genApp'],
    },
    {
      key: 'defaultForAllowIcPoolCollection',
      value: fd['enabled_defaultForAllowIcPoolCollection'],
    },
    {
      key: 'enablePreviousDeclarations',
      value: fd['enabled_enablePreviousDeclarations'],
    },
    {
      key: 'medicationNameMandatoryOnly',
      value: fd['enabled_medicationNameMandatoryOnly'] ? '1' : '0',
    },
    {
      key: 'invoiceAutoApprovalDurationDays',
      value: fd[ENABLED_INVOICE_AUTO_APPROVAL_DURATION_DAYS]
        ? Number(fd[VALUE_INVOICE_AUTO_APPROVAL_DURATION_DAYS])
        : null,
    },
    ...SAMPLE_CODE_TYPES.map((fieldName) => {
      const settingValue = fd[fieldName] as SampleCodeType;
      return {
        key: fieldName,
        // we want to save only these props, enabled is helper variable, not spreading if there would be another props in the future...
        value: !!settingValue?.enabled
          ? Object.fromEntries(
              ['alphanumeric', 'min', 'max'].map((it) => [
                it,
                settingValue[it as keyof SampleCodeType],
              ])
            )
          : null,
      };
    }),
  ];

  yield put(apiCall(TYPE_EDIT_CONTROL_SETTINGS, 'POST', '/client/settings', controlSettings));
  const { success } = yield race({
    success: take(`${TYPE_EDIT_CONTROL_SETTINGS}_SUCCESS`),
    error: take(`${TYPE_EDIT_CONTROL_SETTINGS}_ERROR`),
  });

  yield put(finishEditEntity());

  if (success) {
    yield put(loadProfile());
    successCallback && successCallback();
    toast.success(t('Settings successfully updated'));
  }
}
