import { DateTime } from 'luxon';

import { ClientOptions } from 'app/models/Profile';
import { OTHER_NUMBER } from 'core/components/SelectOtherField';
import { transformDateTimeToDate } from 'core/effects/apiCall/dateTimeTransformations';
import useAppSelector from 'core/hooks/useAppSelector';
import { useClientOptions } from 'core/hooks/useClientOption';
import useAnalysesInputMapping from 'planning/components/AnalysesFields/analysesInputMapping';
import useOfficersInputMapping from 'planning/components/OfficersFieldset/useOfficersInputMapping';
import useSettingsInputMapping from 'planning/components/SettingsTab/useSettingsInputMapping';
import useTeamInputMapping from 'planning/components/TeamTab/useTeamInputMapping';
import Mission from 'planning/models/Mission';

import useMissionSchema, { MissionFormData } from './useMissionSchema';

const useMissionInputMapping = (mode: 'edit' | 'clone' | 'create') => {
  const analysesInputMapping = useAnalysesInputMapping();
  const settingsInputMapping = useSettingsInputMapping();
  const teamInputMapping = useTeamInputMapping(mode);
  const officersInputMapping = useOfficersInputMapping(mode);
  const schema = useMissionSchema();
  const userTimeZonesId = useAppSelector(({ core }) => core.user?.timeZonesId);
  const clientOptions = useClientOptions();

  return (missionData?: Mission): MissionFormData => {
    const { dateFrom, dateTo } = transformDateTimeToDate(missionData || ({} as Mission));

    return {
      ...schema.cast(
        missionData
          ? {
              ...missionData,
              code: mode === 'edit' ? missionData.code || null : '',
              dateRange: dateFrom && dateTo ? { from: dateFrom, to: dateTo } : null,
              timeFrom: missionData.timeFrom && DateTime.fromISO(missionData.timeFrom).toJSDate(),
              timeTo: missionData.timeTo && DateTime.fromISO(missionData.timeTo).toJSDate(),
              dateFromBuffer: mode === 'clone' ? null : missionData.dateFromBuffer,
              dateToBuffer: mode === 'clone' ? null : missionData.dateToBuffer,
              externalId: mode === 'create' ? null : missionData.externalId,
              federationsId: missionData.federationsName ? OTHER_NUMBER : missionData.federationsId,
              eventsId: missionData.eventName ? OTHER_NUMBER : missionData.eventsId,
              debtorsId: missionData.debtorsName ? OTHER_NUMBER : missionData.debtorsId,
              customersId: missionData.customersName ? OTHER_NUMBER : missionData.customersId,
              ...analysesInputMapping(missionData),
              ...teamInputMapping(missionData),
              ...officersInputMapping(missionData),
              ...settingsInputMapping(missionData),
            }
          : {
              timeZonesId: userTimeZonesId,
              resultAuthority: getFromClientOption(clientOptions, 'defaultResultAuthority'),
              testAuthority: getFromClientOption(clientOptions, 'defaultTestAuthority'),
              federationsId: getFromClientOption(clientOptions, 'defaultFederationsId'),
              debtorsId: getFromClientOption(clientOptions, 'defaultDebtorsId'),
              customersId: getFromClientOption(clientOptions, 'defaultCustomersId'),
              sampleAuthoritiesId: getFromClientOption(
                clientOptions,
                'defaultSampleCollectionAuthority'
              ),
              bloodLabsId: getFromClientOption(clientOptions, 'defaultBloodLaboratory'),
              urineLabsId: getFromClientOption(clientOptions, 'defaultUrineLaboratory'),
              initiatorAuthoritiesId: getFromClientOption(clientOptions, 'defaultForDcc'),
              eventName: getFromClientOption(clientOptions, 'defaultForEventName', 'string'),
              notifyOfTeamUpdates: getFromClientOption(
                clientOptions,
                'defaultForNotifyOfTeamUpdates'
              ),
              showAthleteToDco: getFromClientOption(clientOptions, 'defaultForShowAthleteToDco'),
              showInternalCommentToDco: getFromClientOption(
                clientOptions,
                'defaultForShowInternalCommentToDco'
              ),
              use3genApp: getFromClientOption(clientOptions, 'defaultForUse3genApp'),
              allowIcPoolCollection: getFromClientOption(
                clientOptions,
                'defaultForAllowIcPoolCollection'
              ),
            },
        { stripUnknown: true }
      ),
    };
  };
};

const getFromClientOption = (
  clientOptions: undefined | ClientOptions,
  clientOptionName: Extract<keyof ClientOptions, string>,
  type: 'number' | 'string' = 'number'
): null | string | number => {
  const value = clientOptions?.[clientOptionName]?.value;
  if (!value) return null;
  return type === 'number' ? Number(value) : value;
};

export default useMissionInputMapping;
