import { useState, useRef, memo, useEffect, FC, FocusEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { setOpenDropdownId } from 'core/actions';
import useAppSelector from 'core/hooks/useAppSelector';
import { DateTimeFormats } from 'core/i18n/formatDateTime';
import { useFormatDateTime, useFormatDateTimeRange } from 'core/i18n/useFormatDateTime';

import Modal from '../Modal';

import DateRangePicker from './DateRangePicker';
import SingleDayPicker from './SingleDayPicker';
import { StyledWrapper, StyledSelectedText } from './styled';

export interface Props {
  /** handle Date change */
  onChange: (date: Date | Date[] | null) => void;
  /** Input val = single or Array or null */
  value: Date | Date[] | null;
  /** Required value = cannot clear */
  required?: boolean;
  /** Disable changes */
  disabled?: boolean;
  /** Customize print format */
  format?: DateTimeFormats;
  /** Use range picker */
  range?: boolean;
  /** Has input error? */
  error?: boolean;
  /** Initial state */
  initialOpen?: boolean;
  /** Limit date picker min date */
  fromDay?: Date;
  /** Input label */
  label: string;
  /** Limit date picker max date */
  toDay?: Date;
  /** input ID  */
  id: string;
  /** Override minimum width param */
  minWidth?: number | string;
  /** On input blur event handler */
  onBlur?: any;
}

const processInputValue = (value: Date | Date[] | null, range: boolean) => {
  if (range) {
    return Array.isArray(value) ? value : undefined;
  }
  return value || undefined;
};

/**
 * Single or Range Date picker component
 */
const CalendarInput: FC<Props> = ({
  required = false,
  disabled = false,
  range = false,
  format = 'DATE_SHORT',
  initialOpen,
  onChange,
  fromDay,
  toDay,
  label,
  value,
  error,
  onBlur,
  minWidth,
  id,
}) => {
  const [opened, setOpened] = useState(!!initialOpen);
  const dispatch = useDispatch();
  const openDropdownId = useAppSelector(({ core }) => core.openDropdownId);
  const [hasFocus, setHasFocus] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const formatDateTime = useFormatDateTime();
  const formatDateTimeRange = useFormatDateTimeRange();

  const [newValue, setNewValue] = useState<Date | Date[] | undefined>(() =>
    processInputValue(value, range)
  );

  useEffect(() => setNewValue(processInputValue(value, range)), [value, range, required]);

  useEffect(() => {
    setOpened(id === openDropdownId);
  }, [id, openDropdownId]);

  const handleClear = () => {
    setNewValue(undefined);
    onChange(null);
  };

  const handleCancel = () => {
    setOpened(false);
    const oldValue = processInputValue(value, range);
    setNewValue(oldValue);
  };

  const handleOk = () => {
    setOpened(false);
    onChange(newValue || null);
  };

  const handleBlur = (event: FocusEvent<HTMLDivElement>) => {
    if (!opened) {
      setHasFocus(false);
    }
    onBlur && onBlur(event);
  };

  const handleClose = () => {
    setOpened(false);
    if (id === openDropdownId) {
      dispatch(setOpenDropdownId(undefined));
    }
    setTimeout(() => ref.current && ref.current.blur());
  };

  let valueHr;
  if (value === null) {
    valueHr = '';
  } else {
    if (range) {
      const displayValue = Array.isArray(value) ? value : [];
      valueHr = formatDateTimeRange(displayValue[0], displayValue[1], format);
    } else {
      valueHr = formatDateTime(value as Date, format);
    }
  }

  const textClasses = ['mdc-select', 'mdc-select--outlined'];
  const outlineClasses = ['mdc-notched-outline', 'mdc-notched-outline--upgraded'];
  const labelClasses = ['mdc-floating-label'];
  if (hasFocus) {
    textClasses.push('mdc-select--focused');
  }
  if (valueHr) {
    outlineClasses.push('mdc-notched-outline--notched');
    labelClasses.push('mdc-floating-label--float-above');
  }
  if (disabled) {
    textClasses.push('mdc-select--disabled');
  }
  if (error) {
    textClasses.push('mdc-select--invalid');
  }

  return (
    <StyledWrapper>
      <StyledSelectedText minWidth={minWidth} className={textClasses.join(' ')}>
        <span className="mdc-select__dropdown-icon" />
        <div
          onKeyDown={(e) => !disabled && (e.keyCode || e.which) === 13 && setOpened(true)}
          onFocus={() => !disabled && setHasFocus(true)}
          onClick={() => !disabled && setOpened(true)}
          aria-disabled={disabled ? 'true' : 'false'}
          aria-expanded={opened ? 'true' : 'false'}
          className="mdc-select__selected-text"
          aria-labelledby={`${id}-label`}
          tabIndex={disabled ? -1 : 0}
          onBlur={handleBlur}
          role="button"
          ref={ref}
          id={id}
        >
          {valueHr}
        </div>
        <div className={outlineClasses.join(' ')}>
          <div className="mdc-notched-outline__leading" />
          <div className="mdc-notched-outline__notch">
            <label id={`${id}-label`} className={labelClasses.join(' ')}>
              {label}
            </label>
          </div>
          <div className="mdc-notched-outline__trailing" />
        </div>
      </StyledSelectedText>

      {!disabled && (
        <Modal
          onClear={!required ? handleClear : undefined}
          ariaLabel={t('Date Picker Dialog')}
          autoResponsiveWidth={false}
          padding="0"
          onCancel={handleCancel}
          onClose={handleClose}
          onConfirm={handleOk}
          open={opened}
        >
          {range ? (
            <DateRangePicker
              onChange={setNewValue}
              fromDay={fromDay}
              toDay={toDay}
              value={
                ((newValue as Date[]) || []).filter((i) => i).length
                  ? (newValue as Date[])
                  : undefined
              }
            />
          ) : (
            <SingleDayPicker
              value={newValue as Date | undefined}
              onChange={setNewValue}
              fromDay={fromDay}
              toDay={toDay}
            />
          )}
        </Modal>
      )}
    </StyledWrapper>
  );
};

export { CalendarInput as Component };

export default memo(CalendarInput);
