import useModal from 'core/hooks/useModal';

export enum MissionModal {
  EMAILS = 'EMAILS',
  ADD_FILE = 'ADD_FILE',
  BULK_CREATE = 'BULK_CREATE',
  PATCH_TESTS = 'PATCH_TESTS',
  WATCHERS = 'WATCHERS',
  CREATE_TEST_BY_GENDER = 'CREATE_TEST_BY_GENDER',
  CREATE_INVOICE = 'CREATE_INVOICE',
}

export function useMissionModal(id: MissionModal) {
  const open = useModal<MissionModal>();

  return open === id;
}
