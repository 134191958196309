import { DateTime } from 'luxon';

import { StatusColor } from 'app/theme';
import BaseModel from 'core/models/Base';

import { HandoverType } from './Coc';

export enum SampleStatus {
  COLLECTED = 1,
  SHIPPED = 2,
}

export const getSampleStatusName = (sampleStatus?: SampleStatus) => {
  switch (sampleStatus) {
    case SampleStatus.COLLECTED:
      return 'Collected';
    case SampleStatus.SHIPPED:
      return 'Shipped';
    default:
      return 'None';
  }
};

export const getSampleStatusColor = (sampleStatus?: SampleStatus): StatusColor => {
  switch (sampleStatus) {
    case SampleStatus.COLLECTED:
      return 'orange';
    case SampleStatus.SHIPPED:
      return 'green';
    default:
      return 'gray';
  }
};

export enum SampleType {
  PARTIAL = 0,
  URINE = 1,
  ONE_EDTA = 2,
  TWO_EDTA = 3,
  TWO_GEL = 4,
  DBS = 5,
  ONE_EDTA_TWO_GEL = 6,
}

export const getSampleTypeName = (sampleTypeId: SampleType) => {
  switch (sampleTypeId) {
    case SampleType.URINE:
      return 'Urine';
    case SampleType.ONE_EDTA:
      return '1xEDTA';
    case SampleType.TWO_EDTA:
      return '2xEDTA';
    case SampleType.TWO_GEL:
      return '2xGEL';
    case SampleType.ONE_EDTA_TWO_GEL:
      return '1xEDTA+2xGEL';
    case SampleType.DBS:
      return 'DBS';
    default:
      return 'Unknown';
  }
};

export default interface Sample extends BaseModel {
  status: SampleStatus;
  missionsId: number;
  missionCode: string;
  testsId: number;
  testSituationsId: number;
  testResultsId: number;
  sampleCode: string;
  sampleType: number;
  relevantPartialCodes: string | null;
  relevantPartialVolumes: string | null;
  isAgeRequired: boolean;
  ageOfAthlete: number | null;
  collectedBy: string;
  performedAt: DateTime | null;
  gravity: string | null;
  volume: number | null;
  tempLogger: string | null;
  receivedByLab: boolean;
  receivedByLabAt: Date | null;
  resultReported: boolean | null;
  resultReportedAt: Date | null;
  sentToAdams: boolean;
  cocsId: number;
  personalDelivery: boolean;
  waybill: string | null;
  recipientName: string | null;
  witnessName: string | null;
  shippedAt: DateTime | null;
  couriersId: number | null;
  courier: string | null;
  labsId: number;
  lab: string | null;
  testDeleted?: boolean;
  cocDeleted?: boolean;
  missionDeleted?: boolean;
  handoverType?: HandoverType;
  isDraft: boolean;
}
