import { ErrorMessage, FieldProps, getIn } from 'formik';
import { DateTime } from 'luxon';
import { FC, memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import CalendarInput, { Props as CalendarProps } from 'core/components/CalendarInput';
import { FeedbackMessage } from 'core/components/FeedbackMessage';
import { FieldGroup } from 'core/components/FormCalendarInput/styled';
import { Row } from 'core/components/Grid';
import { HalfRowCell } from 'core/components/GridCell';
import TimePickerInput from 'core/components/TimePickerInput';

interface OwnProps {
  label: string;
  icon?: string;
  error?: boolean;
  min?: Date;
  max?: Date;
}

export type Props = OwnProps & CalendarProps & FieldProps<null | Date>;

const FormDateTimeField: FC<Props> = ({
  form: { touched, errors, setFieldValue, setFieldTouched },
  field: { name, value },
  onChange,
  label,
  error,
  ...props
}) => {
  const { t } = useTranslation();

  const hasError: boolean =
    error !== undefined ? error : !!getIn(touched, name) && !!getIn(errors, name);

  const onTimeChange = useCallback(
    (v: Date | Date[] | null) => {
      let val = null;
      if (v instanceof Date) {
        const date = DateTime.fromJSDate(value || new Date());
        const time = DateTime.fromJSDate(v);

        val = date
          .set({
            hour: time.get('hour'),
            minute: time.get('minute'),
            second: time.get('second'),
          })
          .toJSDate();
      }

      if (onChange) {
        onChange(val);
      } else {
        setFieldTouched(name, true);
        setFieldValue(name, val, true);
      }
    },
    [setFieldValue, setFieldTouched, name, onChange, value]
  );

  const onDateChange = useCallback(
    (v: Date | Date[] | null) => {
      let val = null;
      if (v instanceof Date) {
        const date = DateTime.fromJSDate(v);
        const time = DateTime.fromJSDate(props.min || value || v);
        val = date
          .set({
            hour: time.get('hour'),
            minute: time.get('minute'),
            second: time.get('second'),
          })
          .toJSDate();
      }

      if (onChange) {
        onChange(val);
      } else {
        setFieldTouched(name, true);
        setFieldValue(name, val, true);
      }
    },
    [setFieldValue, setFieldTouched, name, onChange, value, props.min]
  );

  const getFieldNameLabel = (fieldName: string, label: string) => {
    if (label) {
      if (label.includes('*')) {
        return `${label.replace('*', '').trim()} ${fieldName} *`;
      }
      return `${label} ${fieldName}`;
    }
    return fieldName;
  };

  const decorateDateLabel = useMemo(() => {
    return getFieldNameLabel(t('Date'), label);
  }, [label, t]);

  const decorateTimeLabel = useMemo(() => {
    return getFieldNameLabel(t('Time'), label);
  }, [label, t]);

  return (
    <Row>
      <HalfRowCell>
        <FieldGroup hasError={hasError}>
          <CalendarInput
            {...props}
            label={decorateDateLabel}
            onChange={onDateChange}
            id={`form-date-input-${name}`}
            value={value || null}
            error={hasError}
          />
          {hasError && <ErrorMessage component={FeedbackMessage} name={name} />}
        </FieldGroup>
      </HalfRowCell>

      <HalfRowCell>
        <FieldGroup hasError={hasError}>
          <TimePickerInput
            {...props}
            value={value || null}
            label={decorateTimeLabel}
            onChange={onTimeChange}
            id={`form-time-input-${name}`}
            error={hasError}
          />
        </FieldGroup>
      </HalfRowCell>
    </Row>
  );
};

export default memo(FormDateTimeField);
