import { FC, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { NavGroup as NavGroupType } from 'app/urls';
import useNavGroups from 'core/hooks/useNavGroups';

import { useGetActiveNavItem } from './activeItemHelpers';
import NavGroup from './NavGroup';
import { NavbarWrap } from './styled';

interface Props {
  collapse: boolean;
}

const Navigation: FC<Props> = ({ collapse }) => {
  const navGroups: NavGroupType[] = useNavGroups();
  const getNavActiveNavGroup = useGetActiveNavItem();
  const { pathname } = useLocation();

  const [openItems, setOpenItems] = useState<Record<string, boolean>>({});

  const toggleOpen = useCallback(
    (key: string) => setOpenItems((s) => (s[key] ? {} : { [key]: true })),
    []
  );

  const openActiveNavGroup = useCallback(() => {
    const g = getNavActiveNavGroup(pathname);
    const items = g ? { [g.key]: true } : {};
    setOpenItems(items);
  }, [getNavActiveNavGroup, pathname]);

  useEffect(() => {
    openActiveNavGroup();
  }, [openActiveNavGroup]);

  useEffect(() => {
    if (collapse) {
      setOpenItems({});
    } else {
      openActiveNavGroup();
    }
  }, [collapse, setOpenItems, openActiveNavGroup]);

  const activeGroup = getNavActiveNavGroup(pathname);

  return (
    <NavbarWrap data-cy="sidebar-nav" data-testId="sidebar-nav">
      {navGroups.map((x: NavGroupType) => {
        if (x.items && x.items.length > 0) {
          return (
            <NavGroup
              collapse={collapse}
              isOpen={openItems[x.key]}
              isActive={activeGroup?.key === x.key}
              toggleOpen={toggleOpen}
              activePath={activeGroup?.path}
              items={x.items}
              title={x.title}
              img={x.img}
              key={x.key}
              id={x.key}
            />
          );
        }

        if (x.to) {
          return (
            <NavGroup
              collapse={collapse}
              key={x.key}
              id={x.key}
              activePath={activeGroup?.path}
              isActive={activeGroup?.key === x.key}
              title={x.title}
              img={x.img}
              items={x.items}
              isOpen={openItems[x.key]}
              toggleOpen={toggleOpen}
              to={x.to}
            />
          );
        }

        return (
          x.items &&
          x.items.length > 0 && (
            <NavGroup
              collapse={collapse}
              isOpen={openItems[x.key]}
              toggleOpen={toggleOpen}
              activePath={activeGroup?.path}
              isActive={activeGroup?.key === x.key}
              items={x.items}
              title={x.title}
              img={x.img}
              key={x.key}
              id={x.key}
            />
          )
        );
      })}
    </NavbarWrap>
  );
};

export default Navigation;
