import { toast } from 'react-toastify';
import { put, race, take } from 'redux-saga/effects';

import {
  apiCall,
  DeleteViewAction,
  FavoriteViewAction,
  PREFIX_DELETE_VIEW,
  PREFIX_SAVE_VIEW,
  SaveViewAction,
  TYPE_FAVORITE_VIEW,
} from 'core/actions';
import { FilterValue } from 'core/containers/DataGrid';
import { t } from 'core/i18n';
import { UserView } from 'core/models/UserView';

import { transformDateToString } from './apiCall/dateTimeTransformations';

export function* saveView(action: SaveViewAction) {
  const { data, previousData, id, successCallback } = action.payload;

  yield put(
    apiCall(
      PREFIX_SAVE_VIEW,
      previousData ? 'PATCH' : 'POST',
      `/users/views/${id || ''}`,
      data,
      undefined,
      true,
      previousData
    )
  );

  const { success } = yield race({
    success: take(`${PREFIX_SAVE_VIEW}_SUCCESS`),
    error: take(`${PREFIX_SAVE_VIEW}_ERROR`),
  });

  if (success) {
    toast.success(t('View was successfully saved.'));

    if (successCallback) {
      const newView = success.payload?.response?.data as UserView;
      const viewFilters = { ...newView.data.filters };

      // When data are retrieved, FE transforms filters so they are DateTime objects,
      // we need to transform them back to strings for proper detection of view changes
      newView.data.filters = Object.keys(viewFilters).reduce(
        (acc: { [key: string]: FilterValue }, key: string) => {
          const value = viewFilters[key];
          if (value instanceof Date) {
            acc[key] = transformDateToString(value, 'DATE');
          } else if (Array.isArray(value)) {
            acc[key] = value.map((v) =>
              (v as any) instanceof Date ? transformDateToString(v as any, 'DATE') : v
            );
          } else {
            acc[key] = value;
          }
          return acc;
        },
        {}
      );

      successCallback(newView);
    }
  }
}

export function* favoriteView(action: FavoriteViewAction) {
  const { successCallback, viewsId, favorite } = action.payload;

  yield put(apiCall(TYPE_FAVORITE_VIEW, 'POST', `/users/views/${viewsId}/favorite`, { favorite }));

  const { success } = yield race({
    success: take(`${TYPE_FAVORITE_VIEW}_SUCCESS`),
    error: take(`${TYPE_FAVORITE_VIEW}_ERROR`),
  });

  if (success) {
    successCallback && successCallback();
  }
}

export function* deleteView(action: DeleteViewAction) {
  const { id, onlyRelation, successCallback } = action.payload;
  const url = onlyRelation ? `/users/views/${id}/relation` : `/users/views/${id}`;

  yield put(apiCall(PREFIX_DELETE_VIEW, 'DELETE', url));

  const { error } = yield race({
    success: take(`${PREFIX_DELETE_VIEW}_SUCCESS`),
    error: take(`${PREFIX_DELETE_VIEW}_ERROR`),
  });

  if (error) {
    return;
  }

  toast.success(t('View was successfully deleted.'));
  successCallback();
}
