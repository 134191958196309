import Tippy from '@tippyjs/react/headless';
import { PropsWithChildren, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Instance } from 'tippy.js';

import Menu, { Item } from 'core/components/Menu';

import { RowAction } from '../..';
import { SetConfirmationRowAction } from '../../props';

import { PopoverWrapper, SmallRippleIconButton } from './styled';

interface Props<T> {
  data: T;
  rowId: number | string;
  rowActions: RowAction<T>[];
  setConfirmationModal: SetConfirmationRowAction;
  reloadData?: () => void;
}

const RowActionsCellRenderer = <T,>({
  data,
  rowId,
  rowActions,
  setConfirmationModal,
  reloadData,
}: PropsWithChildren<Props<T>>) => {
  const tippyRef = useRef<Instance>();
  const { t } = useTranslation();

  const menuItems = rowActions.reduce((actions, action) => {
    if (!action.hidden?.(data)) {
      const decoratedTo =
        action.to && typeof action.to === 'object' && action.to !== null
          ? { url: action.to.url(data), target: action.to.target }
          : action.to;

      actions.push({
        key: action.key,
        text: action.text(data),
        icon: action.icon,
        disabled: action.disabled ? action.disabled(data) : false,
        onClick: () => {
          tippyRef.current?.state?.isDestroyed || tippyRef.current?.hide();
          if (action.confirmation)
            setConfirmationModal({
              ...action,
              onClick: () => action.onClick?.(data, reloadData),
              confirmation: action.confirmation?.(data) || '',
            });
          else action.onClick?.(data, reloadData);
        },
        to: decoratedTo,
      } as Item);
    }
    return actions;
  }, [] as Item[]);

  return (
    <Tippy
      interactive
      trigger={!!menuItems.length ? 'click' : undefined}
      onCreate={(instance) => (tippyRef.current = instance)}
      // We prevent propagation so there is no click on the row itself
      onTrigger={(_, event) => event.stopPropagation()}
      onUntrigger={(_, event) => event.stopPropagation()}
      placement="bottom-end"
      appendTo={document.body}
      offset={[0, -8]}
      render={(attrs) => (
        <PopoverWrapper {...attrs} $noItems={!menuItems.length}>
          {!!menuItems.length ? (
            <Menu
              id={`row-actions-${rowId}`}
              items={menuItems}
              aria-label={t('Quick actions menu')}
              data-cy="rowQuickActionsMenu"
            />
          ) : (
            <>{t('No actions available')}</>
          )}
        </PopoverWrapper>
      )}
    >
      <SmallRippleIconButton
        aria-label={t('Quick actions')}
        data-cy="rowQuickActions"
        icon="more_horiz"
        disabled={!menuItems.length}
      />
    </Tippy>
  );
};

export default RowActionsCellRenderer;
