import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'core/components/Button';
import DetailTimeZoneModal from 'core/components/DetailTimeZoneModal';
import DetailTimeZoneModalWrapper from 'core/components/DetailTimeZoneModal/DetailTimeZoneModalWrapper';
import Tooltip from 'core/components/Tooltip';
import { useFormatDateTime } from 'core/i18n/useFormatDateTime';

import { ModifiedWrap } from './styled';

interface Props {
  modified?: Date;
  created?: Date;
  /**
   * Allows changing the timezone and updating the Formik context if provided.
   * Mandatory for forms that include a timezone value.
   */
  useFormikTimeZone?: boolean;
}

const Time: FC<Props> = ({ modified, created, useFormikTimeZone }) => {
  const { t } = useTranslation();
  const formatDateTime = useFormatDateTime();
  const [modalOpen, setModalOpen] = useState(false);
  const [chosenTimeZone, setChosenTimeZone] = useState<string>();

  let date: string | null = null;
  if (modified) {
    date = formatDateTime(modified, 'DATETIME_SHORT');
  } else if (created) {
    date = formatDateTime(created, 'DATETIME_SHORT');
  }

  const timeBtnText = date || chosenTimeZone || '';

  return (
    <ModifiedWrap>
      <Tooltip content={t('See details and set time zone')} wrapInSpan>
        <Button type="button" text={timeBtnText} onClick={() => setModalOpen(true)} />
      </Tooltip>

      {useFormikTimeZone ? (
        <DetailTimeZoneModalWrapper
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          created={created}
          modified={modified}
          setChosenTimeZone={setChosenTimeZone}
        />
      ) : (
        <DetailTimeZoneModal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          created={created}
          modified={modified}
          usesFormTimeZone={false}
        />
      )}
    </ModifiedWrap>
  );
};

export default Time;
