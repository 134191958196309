import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'core/components/Button';
import DummyField from 'core/components/DummyField';
import Field from 'core/components/Form/Field';
import Modal from 'core/components/Modal';
import ResourceFormDropdown from 'core/components/ResourceFormDropdown';
import { useFormatDateTime } from 'core/i18n/useFormatDateTime';

import { SimpleOption } from '../DropDown';

import { VerticallySpaced } from './styled';

export interface Props {
  modified?: Date | null;
  created?: Date | null;
  open: boolean;
  onClose: () => void;
  usesFormTimeZone: boolean;
  onTimeZoneChange?: (id: number, value: SimpleOption) => void;
}

const DetailTimeZoneModal: FC<Props> = ({
  open,
  modified,
  created,
  onClose,
  usesFormTimeZone,
  onTimeZoneChange,
}) => {
  const { t } = useTranslation();
  const formatDateTime = useFormatDateTime();

  const localTimeZoneDummy = (
    <DummyField
      label={t('Data Time Zone')}
      value={t('Does not contain time zone sensitive data') as string}
    />
  );

  return (
    <Modal
      ariaLabel={t('Creation time, modification time, time zone dialog')}
      onClose={onClose}
      open={open}
      cancelButton={null}
      confirmButton={<Button type="button" text={t('Close')} onClick={onClose} />}
    >
      <VerticallySpaced>
        {created && (
          <DummyField
            label={t('Created')}
            value={
              created
                ? formatDateTime(created, 'DATETIME_SHORT_WITH_SECONDS')
                : (t('Not created yet.') as string)
            }
          />
        )}

        {modified && (
          <DummyField
            label={t('Modified')}
            value={
              modified
                ? formatDateTime(modified, 'DATETIME_SHORT_WITH_SECONDS')
                : (t('Not modified yet.') as string)
            }
          />
        )}

        {usesFormTimeZone ? (
          <Field
            component={ResourceFormDropdown}
            label={t('Data Time Zone')}
            name="timeZonesId"
            id="timeZonesId"
            resource="time-zones"
            onChange={onTimeZoneChange}
            fast={false}
            single
            required
          />
        ) : (
          localTimeZoneDummy
        )}
      </VerticallySpaced>
    </Modal>
  );
};

export default DetailTimeZoneModal;
