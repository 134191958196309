import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { StatusColor } from 'app/theme';
import DetailSubheader from 'core/components/DetailSubheader';
import { RouteTabOption } from 'core/components/DetailSubheader/Tabs';
import { useDetail } from 'core/containers/FormDetailPage/DetailContext';
import Mission, { MissionStatus } from 'planning/models/Mission';

interface Props {
  tabs: RouteTabOption[];
}

const MissionDetailSubheader: FC<Props> = ({ tabs }) => {
  const { entityData: missionData, mode } = useDetail<Mission>();
  const { t } = useTranslation();
  const isEdit = mode === 'edit';

  const getStatus = useCallback(
    (isEdit: boolean, missionData?: Mission): StatusColor | undefined => {
      if (!isEdit) return;
      if (!missionData) return;
      return missionData.status === MissionStatus.OPEN ? 'orange' : 'green';
    },
    []
  );

  const statusText = useMemo(
    () => (isEdit && missionData?.status === MissionStatus.OPEN ? t('Open') : t('Closed')),
    [isEdit, missionData?.status, t]
  );

  const createdAt = useMemo(
    () => (isEdit && missionData?.createdAt) || undefined,
    [isEdit, missionData?.createdAt]
  );

  const modifiedAt = useMemo(
    () => (isEdit && missionData?.modifiedAt) || undefined,
    [isEdit, missionData?.modifiedAt]
  );

  return (
    <DetailSubheader
      statusColor={getStatus(isEdit, missionData)}
      statusText={statusText}
      modified={modifiedAt}
      created={createdAt}
      options={tabs}
      useFormikTimeZone
    />
  );
};

export default MissionDetailSubheader;
